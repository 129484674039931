import { createStore } from 'vuex'
import { db, auth, timestamp } from '../firebase/firebaseinit'

const getDefaultState = () => {
  return {
    restaurantId: '',
    hotelId: '',
    restaurantName: '',
    complexmenu: true,
    isLoading: false,
    menuCategory: {
      id: '',
      active: '',
      available: '',
      categoryname: '',
      icon: '',
      number: null,
    },
    category: {
      id: '',
      categoryname: '',
      majorcategories: [],
      subcategories: [],
      number: null,
    },
    menuItem: {
      id: '',
      active: '',
      available: '',
      categories: [],
      description: '',
      images: '',
      menuitemname: '',
      price: '',
      sizeoption: '',
      subcategories: [],
      thumbnail: '',
      size: [],
      dealid: '',
      dealtype: '',
      discount: null,
    },
    deal: {
      banner: '',
      datefrom: '',
      dateto: '',
      id: '',
      dealitems: [],
      dealname: '',
      dealtype: '',
      description: '',
      discount: null,
    },
    styleSettings: {
      accent: '',
      backgroundcolor: '',
      categorybackgroundcolor: '',
      subcategorybackgroundcolor: '',
      textcolor: '',
    },
    deals: [],
    menuCategories: [],
    categories: [],
    menu: [],
    banner: [],
    isError: false,
  }
}
export default createStore({
  state: getDefaultState(),
  mutations: {
    setRestaurantId(state, payload) {
      state.restaurantId = payload
    },
    setHotelId(state, payload) {
      state.hotelId = payload
    },
    setLoadingStatus(state, payload) {
      state.isLoading = payload
    },
    setRestaurantName(state, payload) {
      state.restaurantName = payload
    },
    setComplexMenu(state, payload) {
      state.complexmenu = payload
    },
    setUIsetting(state, payload) {
      let style = {
        accent: payload.accent,
        backgroundcolor: payload.backgroundcolor,
        categorybackgroundcolor: payload.categorybackgroundcolor,
        subcategorybackgroundcolor: payload.subcategorybackgroundcolor,
        textcolor: payload.categorytextcolor,
      }
      state.styleSettings = style
    },
    setMenuCategory(state, payload) {
      /** Check if menu category does not exists in menu categories before adding it */
      if (
        !state.menuCategories.some((category) => category.categoryname === payload.categoryname) &&
        payload.active === 'active' &&
        payload.available === 'available'
      ) {
        state.menuCategory = {
          id: payload.id,
          active: payload.active,
          available: payload.available,
          categoryname: payload.categoryname,
          icon: payload.icon,
          number: payload.number,
        }
        state.menuCategories.push(state.menuCategory)
      }
    },
    setMenuCategoryUpdate(state, payload) {
      const item = state.menuCategories.find((item) => item.id === payload.id)
      if (item && payload.active === 'active' && payload.available === 'available') {
        state.menuCategory = {
          id: payload.id,
          active: payload.active,
          available: payload.available,
          categoryname: payload.categoryname,
          icon: payload.icon,
          number: payload.number,
        }
        return Object.assign(item, state.menuCategory)
      }
      if (!item && payload.active === 'active' && payload.available === 'available') {
        state.menuCategory = {
          id: payload.id,
          active: payload.active,
          available: payload.available,
          categoryname: payload.categoryname,
          icon: payload.icon,
          number: payload.number,
        }
        state.menuCategories.push(state.menuCategory)
      }
      if (payload.active != 'active' || payload.available != 'available') {
        return state.menuCategories.splice(
          state.menuCategories.findIndex((item) => item.id === payload.id),
          1
        )
      }
    },
    setDeleteMenuCategory(state, payload) {
      return state.menuCategories.splice(
        state.menuCategories.findIndex((item) => item.id === payload),
        1
      )
    },
    setCategories(state, payload) {
      if (!state.categories.some((data) => data.categoryname === payload.categoryname)) {
        if (payload.majorcategories) {
          state.category = {
            id: payload.id,
            categoryname: payload.categoryname,
            number: payload.number,
            majorcategories: [...payload.majorcategories],
            subcategories: [...payload.subcategories],
          }
        } else {
          state.category = {
            id: payload.id,
            active: payload.active,
            categoryname: payload.categoryname,
            number: payload.number,
            subcategories: [...payload.subcategories],
          }
        }
        state.categories.push(state.category)
      }
    },
    setUpdateCategories(state, payload) {
      const item = state.categories.find((item) => item.id === payload.id)
      if (item && payload.majorcategories) {
        state.category = {
          id: payload.id,
          categoryname: payload.categoryname,
          number: payload.number,
          majorcategories: [...payload.majorcategories],
          subcategories: [...payload.subcategories],
        }
      } else {
        state.category = {
          id: payload.id,
          active: payload.active,
          categoryname: payload.categoryname,
          number: payload.number,
          subcategories: [...payload.subcategories],
        }
      }
      Object.assign(item, state.category)
    },
    setDeleteCategories(state, payload) {
      state.categories.splice(
        state.categories.findIndex((item) => item.id === payload),
        1
      )
    },
    setMenu(state, payload) {
      if (!state.menu.some((item) => item.menuitemname === payload.menuitemname)) {
        if (payload.size) {
          state.menuItem = {
            id: payload.id,
            active: payload.active,
            available: payload.available,
            categories: [...payload.categories],
            description: payload.description,
            images: payload.images,
            menuitemname: payload.menuitemname,
            price: payload.price,
            sizeoption: payload.sizeoption,
            subcategories: [...payload.subcategories],
            size: [...payload.size],
            thumbnail: payload.thumbnail,
          }
        } else {
          state.menuItem = {
            id: payload.id,
            active: payload.active,
            available: payload.available,
            categories: [...payload.categories],
            description: payload.description,
            images: payload.images,
            menuitemname: payload.menuitemname,
            price: payload.price,
            sizeoption: payload.sizeoption,
            subcategories: [...payload.subcategories],
            thumbnail: payload.thumbnail,
          }
        }
        state.menu.push(state.menuItem)
      }
    },
    setMenuUpdate(state, payload) {
      const item = state.menu.find((item) => item.id === payload.id)
      if (item && payload.size) {
        state.menuItem = {
          id: payload.id,
          active: payload.active,
          available: payload.available,
          categories: [...payload.categories],
          description: payload.description,
          images: payload.images,
          menuitemname: payload.menuitemname,
          price: payload.price,
          sizeoption: payload.sizeoption,
          subcategories: [...payload.subcategories],
          size: [...payload.size],
          thumbnail: payload.thumbnail,
        }
      } else {
        state.menuItem = {
          id: payload.id,
          active: payload.active,
          available: payload.available,
          categories: [...payload.categories],
          description: payload.description,
          images: payload.images,
          menuitemname: payload.menuitemname,
          price: payload.price,
          sizeoption: payload.sizeoption,
          subcategories: [...payload.subcategories],
          thumbnail: payload.thumbnail,
        }
      }
      Object.assign(item, state.menuItem)
    },
    setDeleteMenu(state, payload) {
      state.menu.splice(
        state.menu.findIndex((item) => item.id === payload),
        1
      )
    },
    setDeals(state, payload) {
      let dealdate = new Date().getTime() > new Date(payload.dateto).getTime()

      if (!state.deals.some((item) => item.id === payload.id) && dealdate == false) {
        state.deal = {
          banner: payload.banner,
          datefrom: payload.datefrom,
          dateto: payload.dateto,
          id: payload.id,
          dealitems: [...payload.dealitems],
          dealname: payload.dealname,
          dealtype: payload.dealtype,
          description: payload.description,
          discount: payload.discount,
        }
        return state.deals.push(state.deal)
      }
    },
    setDealsUpdate(state, payload) {
      const item = state.deals.find((item) => item.id === payload.id)
      let dealdate = new Date().getTime() > new Date(payload.dateto).getTime()

      if (dealdate === false) {
        if (item != undefined) {
          state.deal = {
            banner: payload.banner,
            datefrom: payload.datefrom,
            dateto: payload.dateto,
            dealitems: [...payload.dealitems],
            dealname: payload.dealname,
            dealtype: payload.dealtype,
            description: payload.description,
            discount: payload.discount,
          }
          payload.dealitems.forEach((item) => {
            state.menu.forEach((menuItem) => {
              if (menuItem.id === item) {
                if (menuItem.dealid != '') {
                  if (menuItem.dealid === payload.id) {
                    menuItem.dealtype = payload.dealtype
                    menuItem.discount = payload.discount
                  }
                } else {
                  menuItem.dealid = payload.id
                  menuItem.dealtype = payload.dealtype
                  menuItem.discount = payload.discount
                }
              }
            })
          })
          return Object.assign(item, state.deal)
        } else {
          state.deal = {
            id: payload.id,
            banner: payload.banner,
            datefrom: payload.datefrom,
            dateto: payload.dateto,
            dealitems: [...payload.dealitems],
            dealname: payload.dealname,
            dealtype: payload.dealtype,
            description: payload.description,
            discount: payload.discount,
          }
          payload.dealitems.forEach((item) => {
            state.menu.forEach((menuItem) => {
              if (menuItem.id === item) {
                if (menuItem.dealid != '') {
                  if (menuItem.dealid === payload.id) {
                    menuItem.dealtype = payload.dealtype
                    menuItem.discount = payload.discount
                  }
                } else {
                  menuItem.dealid = payload.id
                  menuItem.dealtype = payload.dealtype
                  menuItem.discount = payload.discount
                }
              }
            })
          })
          return state.deals.push(state.deal)
        }
      } else {
        if (item != undefined) {
          payload.dealitems.forEach((item) => {
            state.menu.forEach((menuItem) => {
              if (menuItem.id === item) {
                menuItem.dealid = ''
                menuItem.dealtype = ''
                menuItem.discount = null
              }
            })
          })
          return state.deals.splice(
            state.deals.findIndex((item) => item.id === payload.id),
            1
          )
        }
      }
    },
    setDeleteDeals(state, payload) {
      payload.dealitems.forEach((item) => {
        state.menu.forEach((menuItem) => {
          if (menuItem.id === item) {
            menuItem.dealid = ''
            menuItem.dealtype = ''
            menuItem.discount = null
          }
        })
      })
      return state.deals.splice(
        state.deals.findIndex((item) => item.id === payload.id),
        1
      )
    },
    setBanner(state, payload) {
      if (!state.banner.includes(payload)) {
        state.banner.push(payload)
      }
    },
    setError(state, payload) {
      return (state.isError = payload)
    },
    resetState(state) {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    async getRestaurant({ commit }, payload) {
      try {
        if (typeof Storage != undefined) {
          localStorage.setItem('hotelId', payload.hotelId)
          localStorage.setItem('restaurantId', payload.restaurantId)
        }
        commit('setLoadingStatus', true)
        const hotelsRef = db.collection('hotels')
        const hotelQuery = hotelsRef.where('hoteluniqueid', '==', payload.hotelId)
        const querySnapshot = await hotelQuery.get()
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          commit('setHotelId', doc.id)
          const documentRef = hotelsRef.doc(doc.id)
          getStoreLocations(commit, documentRef, payload.restaurantId)
        })
      } catch (error) {
        commit('setLoadingStatus', false)
        commit('setError', true)
      }
    },
    async postFeedBack({ commit }, payload) {
      try {
        let uid = auth.currentUser.uid
        await db
          .collection('feedback')
          .doc()
          .set({
            user: uid,
            hotel: payload.hotel,
            restaurant: payload.restaurant,
            feedback: payload.feedback,
            timestamp: timestamp,
          })
      } catch (err) {
        commit('setError', true)
      }
    },
    loadingStatus({ commit }, payload) {
      commit('setLoadingStatus', payload)
    },
    clearStore({ commit }) {
      commit('resetState')
    },
  },
  getters: {
    loadingStatus: (state) => {
      return state.isLoading
    },
    getMainIds: (state) => {
      return {
        hotelUId: state.hotelId,
        restaurantUId: state.restaurantId,
      }
    },
    getMenuCategories: (state) => {
      return state.menuCategories
    },
    getBanner: (state) => {
      return state.banner
    },
    getDisplayCategories: (state) => {
      return state.categories
    },
    getDisplayMenu: (state) => {
      return state.menu
    },
    getDeals: (state) => {
      return state.deals
    },
    getStyle: (state) => {
      return state.styleSettings
    },
    getError: (state) => {
      return state.isError
    },
  },
  modules: {},
})

async function getStoreLocations(commit, hotelsRef, restaurantId) {
  try {
    const restaurantsRef = hotelsRef.collection('storelocations')
    const restaurantQuery = restaurantsRef.where('storeuniqueid', '==', restaurantId)
    const querySnapshot = await restaurantQuery.get()
    querySnapshot.forEach((doc) => {
      commit('setRestaurantId', doc.id)

      const complex = doc.data().complexmenu
      commit('setComplexMenu', complex)
      const customui = doc.data().customui

      const storeName = doc.data().storename
      const storeNameArray = storeName.split(',')
      commit('setRestaurantName', storeNameArray[0])
      const storelocation = doc.data().storelocation
      if (typeof Storage != undefined) {
        localStorage.setItem('storename', storeNameArray[0])
        localStorage.setItem('storelocation', storelocation)
      }
      const documentRef = restaurantsRef.doc(doc.id)
      getBanners(commit, documentRef)
      getMajorCategories(commit, documentRef)
      getCategories(commit, documentRef)
      getMenu(commit, documentRef)
      getDeals(commit, documentRef)
      getUISettings(customui, commit, documentRef)
    })
  } catch (error) {
    commit('setLoadingStatus', false)
    commit('setError', true)
  }
}
async function getUISettings(customui, commit, restaurantsRef) {
  try {
    const uiSettingsRef = restaurantsRef.collection('uisettings')
    const querySnapshot = await uiSettingsRef.get()
    querySnapshot.forEach((doc) => {
      const settings = doc.data()
      if (settings) {
        if (customui) {
          commit('setUIsetting', settings)
        }
        commit('setRestaurantName', settings.titlename)
        if (typeof Storage != undefined && settings.titlename) {
          localStorage.setItem('storename', settings.titlename)
        }
      }
    })
  } catch (error) {
    commit('setError', true)
  }
}
async function getMajorCategories(commit, restaurantsRef) {
  try {
    const majorCategoriesRef = restaurantsRef.collection('majorcategories').orderBy('number')
    await majorCategoriesRef.onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
        // doc.data() is never undefined for query doc snapshots
        let mainCat = change.doc.data()
        if (change.type === 'added') {
          commit('setLoadingStatus', false)
          commit('setMenuCategory', {
            id: change.doc.id,
            active: mainCat.active,
            available: mainCat.available,
            categoryname: mainCat.categoryname,
            icon: mainCat.icon,
            number: mainCat.number,
          })
        }
        if (change.type === 'modified') {
          commit('setLoadingStatus', false)
          commit('setMenuCategoryUpdate', {
            id: change.doc.id,
            active: mainCat.active,
            available: mainCat.available,
            categoryname: mainCat.categoryname,
            icon: mainCat.icon,
            number: mainCat.number,
          })
        }
        if (change.type === 'removed') {
          commit('setLoadingStatus', false)
          commit('setDeleteMenuCategory', change.doc.id)
        }
      })
    })
  } catch (error) {
    commit('setError', true)
  }
}
async function getBanners(commit, restaurantRef) {
  try {
    const bannerRef = restaurantRef.collection('promotions')
    const querySnapshot = await bannerRef.get()
    querySnapshot.forEach((doc) => {
      const data = doc.data()
      commit('setBanner', data.banner)
    })
  } catch (error) {
    commit('setError', true)
  }
}
async function getCategories(commit, restaurantsRef) {
  try {
    const categoriesRef = restaurantsRef.collection('categories').orderBy('number')
    await categoriesRef.onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
        let categoryData = change.doc.data()
        if (change.type === 'added') {
          if (categoryData.majorcategories) {
            commit('setCategories', {
              id: change.doc.id,
              categoryname: categoryData.categoryname,
              number: categoryData.number,
              majorcategories: categoryData.majorcategories,
              subcategories: categoryData.subcategories,
            })
          } else {
            commit('setCategories', {
              id: change.doc.id,
              categoryname: categoryData.categoryname,
              number: categoryData.number,
              subcategories: categoryData.subcategories,
            })
          }
        }
        if (change.type === 'modified') {
          let categoryData = change.doc.data()
          if (categoryData.majorcategories) {
            commit('setUpdateCategories', {
              id: change.doc.id,
              categoryname: categoryData.categoryname,
              number: categoryData.number,
              majorcategories: categoryData.majorcategories,
              subcategories: categoryData.subcategories,
            })
          } else {
            commit('setUpdateCategories', {
              id: change.doc.id,
              categoryname: categoryData.categoryname,
              number: categoryData.number,
              subcategories: categoryData.subcategories,
            })
          }
        }
        if (change.type === 'removed') {
          commit('setDeleteCategories', change.doc.id)
        }
      })
    })
  } catch (error) {
    commit('setError', true)
  }
}
async function getMenu(commit, restaurantsRef) {
  try {
    const menuRef = restaurantsRef.collection('menu').orderBy('menuitemname')
    await menuRef.onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
        let menuData = change.doc.data()
        if (change.type === 'added') {
          if (menuData.size) {
            commit('setMenu', {
              id: change.doc.id,
              active: menuData.active,
              available: menuData.available,
              categories: [...menuData.categories],
              description: menuData.description,
              images: menuData.images,
              menuitemname: menuData.menuitemname,
              price: menuData.price,
              sizeoption: menuData.sizeoption,
              subcategories: [...menuData.subcategories],
              size: [...menuData.size],
              thumbnail: menuData.thumbnail,
            })
          } else {
            commit('setMenu', {
              id: change.doc.id,
              active: menuData.active,
              available: menuData.available,
              categories: [...menuData.categories],
              description: menuData.description,
              images: menuData.images,
              menuitemname: menuData.menuitemname,
              price: menuData.price,
              sizeoption: menuData.sizeoption,
              subcategories: [...menuData.subcategories],
              thumbnail: menuData.thumbnail,
            })
          }
        }
        if (change.type === 'modified') {
          let menuData = change.doc.data()
          if (menuData.size) {
            commit('setMenuUpdate', {
              id: change.doc.id,
              active: menuData.active,
              available: menuData.available,
              categories: [...menuData.categories],
              description: menuData.description,
              images: menuData.images,
              menuitemname: menuData.menuitemname,
              price: menuData.price,
              sizeoption: menuData.sizeoption,
              subcategories: [...menuData.subcategories],
              size: [...menuData.size],
              thumbnail: menuData.thumbnail,
            })
          } else {
            commit('setMenuUpdate', {
              id: change.doc.id,
              active: menuData.active,
              available: menuData.available,
              categories: [...menuData.categories],
              description: menuData.description,
              images: menuData.images,
              menuitemname: menuData.menuitemname,
              price: menuData.price,
              sizeoption: menuData.sizeoption,
              subcategories: [...menuData.subcategories],
              thumbnail: menuData.thumbnail,
            })
          }
        }
        if (change.type === 'removed') {
          commit('setDeleteMenu', change.doc.id)
        }
      })
    })
  } catch (error) {
    commit('setError', true)
  }
}
async function getDeals(commit, restaurantsRef) {
  try {
    const dealsRef = restaurantsRef.collection('deals')
    await dealsRef.onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
        let dealsData = change.doc.data()
        if (change.type === 'added') {
          commit('setDeals', {
            id: change.doc.id,
            banner: dealsData.banner,
            datefrom: dealsData.datefrom,
            dateto: dealsData.dateto,
            dealitems: [...dealsData.dealitems],
            dealname: dealsData.dealname,
            dealtype: dealsData.dealtype,
            description: dealsData.description,
            discount: dealsData.discount,
          })
        }
        if (change.type === 'modified') {
          let dealsData = change.doc.data()
          commit('setDealsUpdate', {
            id: change.doc.id,
            banner: dealsData.banner,
            datefrom: dealsData.datefrom,
            dateto: dealsData.dateto,
            dealitems: [...dealsData.dealitems],
            dealname: dealsData.dealname,
            dealtype: dealsData.dealtype,
            description: dealsData.description,
            discount: dealsData.discount,
          })
        }
        if (change.type === 'removed') {
          commit('setDeleteDeals', change.doc.data())
        }
      })
    })
  } catch (error) {
    commit('setError', true)
  }
}
