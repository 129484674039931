import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCh_afQm6UEHvpiBIlsfmEz98w4e3UKva0',
  authDomain: 'tap4menu-30b19.firebaseapp.com',
  databaseURL: 'https://tap4menu-30b19.firebaseio.com',
  projectId: 'tap4menu-30b19',
  storageBucket: 'tap4menu-30b19.appspot.com',
  messagingSenderId: '893849768512',
  appId: '1:893849768512:web:3894bead8e66a4a43005a5',
  measurementId: 'G-RMJXJEXPJM',
}

firebase.initializeApp(firebaseConfig)
// utils
const db = firebase.firestore()
const auth = firebase.auth()
const timestamp = firebase.firestore.FieldValue.serverTimestamp()
const offline = db.enablePersistence().catch(() => {
  // if (err.code == 'failed-precondition') {
  //   // Multiple tabs open, persistence can only be enabled
  //   // in one tab at a a time.
  //   // ...
  // } else if (err.code == 'unimplemented') {
  //   // The current browser does not support all of the
  //   // features required to enable persistence
  //   // ...
  // }
})
export { db, auth, timestamp, offline }
