<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-tabs>
        <!-- Tab bar -->
        <ion-router-outlet />
        <ion-tab-bar slot="bottom">
          <ion-tab-button tab="menu" href="/home/">
            <ion-icon :icon="restaurantOutline"></ion-icon>
            <ion-label>Menu</ion-label>
          </ion-tab-button>

          <ion-tab-button tab="search" href="/home/search">
            <ion-icon :icon="searchOutline"></ion-icon>
            <ion-label>Search</ion-label>
          </ion-tab-button>
        </ion-tab-bar>
      </ion-tabs>
    </ion-content>
  </ion-page>
</template>
<script>
  import {
    IonPage,
    IonContent,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonLabel,
    IonRouterOutlet,
  } from '@ionic/vue'
  import { restaurantOutline, searchOutline } from 'ionicons/icons'
  export default {
    name: 'Tabs',
    props: {
      hotelId: {
        type: String,
      },
      restaurantId: {
        type: String,
      },
    },
    provide() {
      return {
        hotelId: this.hotelId,
        restaurantId: this.restaurantId,
      }
    },
    components: {
      IonPage,
      IonContent,
      IonTabs,
      IonTabBar,
      IonTabButton,
      IonIcon,
      IonLabel,
      IonRouterOutlet,
    },
    data() {
      return {
        restaurantOutline,
        searchOutline,
      }
    },
    beforeCreate() {
      if (!this.hotelId || !this.restaurantId) {
        return this.$router.replace({ name: 'Landing' })
      }
    },
    computed: {
      searchParams() {
        return { hotelId: this.hotelId, restaurantId: this.restaurantId }
      },
    },
  }
</script>
